<template>
  <div>
    <div class="container">
      <div
        class="card mt-4"
        style="max-width: 800px; margin: 0 auto !important"
      >
        <div class="card-body">
          <Filtros :title="`Vendas X Convênio`" @filtrar="filtrar" :initial="filtros">
            <div class="row pb-2">
              <div class="col-12 col-sm-12 text-left pr-3">
                <label style="margin-bottom:7px"><i class="fa fa-industry mr-1"/> Convênio</label>
                <v-select :options="listaConvenios" label="razao_social" multiple v-model="filtros.convenios" />
              </div>
            </div>
          </Filtros>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filtros from "../../components/Relatorios/Filtros.vue";
import EntidadesLib from "../../libs/EntidadesLib";

export default {
  components: {
    Filtros,
  },
  props: {},
  mounted() {
    this.carregarConvenios();
  },
  data() {
    return {
      filtros: {},
      listaConvenios:[],
    };
  },
  watch: {},
  computed: {},
  methods: {
    async carregarConvenios() {
      try {
        let res = await EntidadesLib.getResumido(null, null, {},"convenio");
        console.log('res conv', res);
        if (res && res.entidades) {
          this.listaConvenios = res.entidades;
        }
      } catch (err) {
        console.log("ERROR ON GET CONVENIOS", err);
       }
    },
    async filtrar() {},
  },
};
</script>

<style lang="scss" scoped></style>
